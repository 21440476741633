body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7F8FE;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #2244b9;
}

.rt-resizable-header-content {
  font-weight: bold;
  text-align: left;
}

.grabba-footer{
  margin-top: 1rem;
  padding: 1rem;
  vertical-align: middle;
  background-color: #2244b9;
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: left;
}

.grabba-table-header{
  background-color: #000A2C;
  color: white;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  width: 300px;
  height: 300px;
  display: inline-block;
  vertical-align: center;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}
